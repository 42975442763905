var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{attrs:{"wrap":"","pt-16":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs11":"","sm6":"","md5":"","pl-lg-4":""}},[_c('v-img',{attrs:{"src":require("./../../assets/images/aboutmain.png")}})],1),_c('v-flex',{attrs:{"xs11":"","sm6":"","md7":"","pl-0":"","pl-xs-0":"","pl-sm-8":"","pl-md-16":"","pl-lg-16":"","pl-xl-16":"","align-self-center":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"x12":""}},[_c('span',{staticStyle:{"font-family":"OutfitExtraBold","color":"#1C6DC1","font-size":"20px"}},[_vm._v("ABOUT US")]),_c('br'),_c('span',{staticStyle:{"font-family":"OutfitExtraBold","color":"black","text-align":"justify"},style:({
                  'line-height': _vm.$vuetify.breakpoint.name == 'xs' ? '30px' : _vm.$vuetify.breakpoint.name == 'xl' ? '30px' : '35px',
                  'font-size': _vm.$vuetify.breakpoint.name == 'xs' ? '25px' : _vm.$vuetify.breakpoint.name == 'xl' ? '10px' : '30px',
                })},[_vm._v("Insightful Look into Our "),_c('span',{staticStyle:{"color":"#1C6DC1"}},[_vm._v("Company")])])]),_c('v-flex',{attrs:{"xs12":"","sm11":"","md12":"","lg-10":"","pl-sm-5":"","pt-2":"","pt-lg-6":"","text-justify":""}},[_c('span',{staticStyle:{"font-family":"JostRegular","text-align":"justify","font-size":"16px"}},[_vm._v("At Kumarakom Ventures, we pride ourselves on our unwavering commitment to providing innovative solutions, unparalleled expertise, and unparalleled customer service. Our strategic location in Kanjirappally places us at the heart of one of Kerala's most dynamic economic hubs, enabling us to tap into the region's rich potential and drive sustainable growth for our clients and partners alike. "),_c('br'),_c('br'),_vm._v(" With a firm belief in the power of collaboration and innovation, we harness the collective talents and resources of our dedicated team to deliver tailor-made solutions that meet the evolving needs of our clients across various industries and sectors. Whether you are a local entrepreneur seeking to expand your business horizons or a global corporation looking to unlock new opportunities in the Indian market, Kumarakom Ventures is here to support you every step of the way.")])])],1)],1),_c('v-layout',{attrs:{"wrap":"","py-10":"","data-aos":"flip-up","data-aos-ease":"ease","data-aos-duration":"1500","data-aos-delay":"100"}},[_c('v-flex',{style:({
            'padding': (_vm.$vuetify.breakpoint.name == 'xs') ? '20px' : (_vm.$vuetify.breakpoint.name == 'sm') ? '30px' : '60px'
          }),attrs:{"xs12":""}},[_c('v-card',{style:({
              background:
                'linear-gradient(356deg, #51A6FF 0%, #5995EE 100%)',
            }),attrs:{"rounded":"xl","outlined":""}},[_c('v-layout',{attrs:{"wrap":"","justify-center":"","py-10":""}},[_c('v-flex',{attrs:{"xs11":"","sm11":"","md11":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',{staticStyle:{"font-family":"JostSemiBold","color":"white","font-size":"30px"}},[_vm._v("VISION")]),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"JostRegular","color":"white","font-size":"22px"}},[_vm._v("Transforming dreams into reality.")])]),_c('v-flex',{attrs:{"xs12":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"JostSemiBold","color":"white","font-size":"30px"}},[_vm._v("MISSION")]),_c('br'),_c('br'),_c('span',{staticStyle:{"font-family":"JostRegular","color":"white","font-size":"22px"}},[_vm._v("Our mission is to ignite the spark of possibility.")])])],1)],1),_c('v-flex',{staticClass:"text-center",attrs:{"xs12":"","sm6":"","align-self-center":"","pt-5":""}},[_c('span',{staticStyle:{"font-family":"OutfitBold","color":"white","font-size":"35px"}},[_vm._v("Build The Skills "),_c('br'),_vm._v(" To Drive Your Career.")])])],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }